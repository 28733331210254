import React, {Component} from 'react';

class Title extends Component {

  render() {
    return (
      <div>
        <h1 className="title">Elliot Barnwell</h1>
      </div>
    );
  }
}

export default Title
