import React, {Component} from 'react';
class Summary extends Component {
  render() {
    return (
      <div className="summary-div">
        <p>I'm a full stack software engineer with strong expertise in testing infrastructure and build tools. Passionate about anything autonomous, excited about improving AI/ML validation
        </p>
        </div>
    );
  }
}

export default Summary
